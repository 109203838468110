import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { playerConfig } from '../Helpers/constants'

const { MobileBreakPoint } = playerConfig

const SubtitlesWrp = styled.div`
  position: absolute;
  bottom: 20%;
  z-index: 11;
  color: white;
  text-align: center;
  width: 79px;
  height: 89px;
  flex-grow: 0;
  background-color: #000;
  box-shadow: ${props =>
    props.theme.userActive
      ? '4px 5px 7px 0 rgba(0, 0, 0, 0.4)'
      : '0 0 7px 0 transparent'};
  transition: all 0.2s ease;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  left: ${props => (props.theme.userActive ? '0' : '-80px')};

  div.wrapper {
    width: 59px;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    padding: 0;

    div {
      span {
        display: block;
        width: 59px;
        height: 22px;
        flex-grow: 0;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }

    img {
      width: 25px;
      height: 24px;
    }
  }

  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  &.off {
    border-color: black;
  }

  &.on {
    border-color: white;
  }

  @media @max1280 {
    left: 0;
    top: 0;
    transform: scale(0.5);
    transform-origin: 0 0;
  }

  @media (max-width: ${MobileBreakPoint}px) {
    border: 1.5px solid white;
    border-left: none;
    border-radius: 0 5px 5px 0;
    width: 47px;
    height: 56px;
    object-fit: contain;
    box-shadow: -4px 5px 7px 0 rgba(0, 0, 0, 0.4);
    top: 10px;
    bottom: unset;
    div.wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: auto;
      gap: 2px;

      div {
        span {
          transform: scale(80%);
          width: 100%;
          height: unset;
          flex-grow: 0;
          font-size: 12px;
          line-height: 13px;
        }
      }

      img {
        width: 19px;
        height: 17.9px;
        flex-grow: 0;
        object-fit: contain;
      }
    }
  }

  @media (orientation: landscape) {
    top: unset;
    bottom: 20%;
  }
`

interface SubTitlesBtnProps {
  subTitlesOn: boolean
  toggle: () => void
  theme: {
    userActive: boolean
  }
}

export const SubTitlesBtn: React.FC<SubTitlesBtnProps> = props => {
  const subTitleIcons =
    playerConfig.fakeWindow.siteConfig.default_images.subtitleBtnIcons
  return (
    <SubtitlesWrp
      className={cx(['accessibilityBtn', 'on'])}
      role={'button'}
      onClick={props.toggle}
      theme={{ ...props.theme }}
    >
      <div className='wrapper'>
        <img
          src={props.subTitlesOn ? subTitleIcons.on : subTitleIcons.off}
          alt={props.subTitlesOn ? 'כיבוי' : 'הפעלת'}
        />
        <div>
          <span>{props.subTitlesOn ? 'כיבוי' : 'הפעלת'}</span>
          <span>כתוביות</span>
        </div>
      </div>
    </SubtitlesWrp>
  )
}

import styled, { createGlobalStyle } from 'styled-components'
import { colors, fonts } from './GlobalVariables'

const GlobalStyle = createGlobalStyle<{
  darkTheme?: boolean
  isPlayerFullscreen?: boolean
}>`
  html, body {
    background-color: ${({ isPlayerFullscreen }) => {
      if (isPlayerFullscreen) {
        return colors.black
      }
    }};

    font-family: ${fonts.default};
    height: 100%;
    direction: rtl;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    /* Allows for white-space: nowrap to work with flex */
    min-width: 0;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%; // prevent images from overflowing
  }
`

export default GlobalStyle

export const EmbedWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${colors.tvGuideDark};
  display: grid;
  align-items: center;
  justify-items: stretch;
`

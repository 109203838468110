/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */

import { useDetectAdBlock } from 'adblock-detect-react'
import cx from 'classnames'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Cookies from 'universal-cookie'
import { isDesktop } from '../../client'
import { usePage } from '../../contexts'
import { IVideo } from '../../types'
import { Copyrights } from './Copyrights'
import { mode, playerConfig, subtitlesData, utils } from './Helpers'
import * as gtag from './Helpers/gtag'
import { Kaltura } from './Kaltura/Kaltura'
import {
  KalturaProps,
  LiveRefEntryKey,
  PlayerPropsModel,
} from './Models/PlayerModels'
import { PlayerContext } from './Store/PlayerContext'
import {
  DescriptionAndCredit,
  ImgWithRatio,
  StyledPlayerComp,
  VideoPlaceHolder,
  WrapStyledPlayerComp,
} from './Styles/Player.styles'
import { SubTitlesBtn } from './SubTitlesBtn/SubTitlesBtn'
import { prettyLog } from '../../utils'
import { usePlayerStore, useRootStore } from '../../store'
import useGeoManifestFetch from './Helpers/useGeoManifestFetch'
import GeoTestButton from './components/GeoTestButton'
import { imageSrcBlockAds } from '../../styles/GlobalVariables'
import { useTaboola } from '../Taboola/useTaboola'

export const Player = (props: PlayerPropsModel) => {
  const {
    video,
    img,
    pageMeta,
    fromParser = false,
    item,
    videoWithSubtitles,
    playerOnly,
  } = props

  let inChanel26 = false
  const page = usePage()
  const { liveEntries, sendPal } = page?.SiteMeta?.config?.player || {}
  const playerCtx = useContext(PlayerContext)
  const { playerStatePaused, playerTitle } = usePlayerStore()
  const [playerScript, isShowingMaavaron] = useRootStore(state => [
    state.playerScript,
    state.isShowingMaavaron,
  ])

  const [autoPlay, setAutoPlay] = useState<boolean | null>(null)
  const loadKantarConfig = playerConfig.fakeWindow.thirdParty.kantar
  const { setTargetPlayingNow } = playerCtx

  const cookies = new Cookies()
  const subTitlesOnCookie = cookies.get(
    subtitlesData.video_accessibility_cookie_name
  )
  const [userActive, setUserActive] = useState(false)
  const [subTitlesOn, setSubTitlesOn] = useState(subTitlesOnCookie === 'true')
  const [showSubTitleBtn, setShowSubTitleBtn] = useState(false)

  let { credit, description, duration, kalturaId, name, poster } =
    showSubTitleBtn && subTitlesOn && !utils.isLive(video.name, liveEntries)
      ? (videoWithSubtitles as IVideo)
      : video
  const { copyright, pageType, ads, levels } = pageMeta

  const descriptionAdCreditEl = useMemo(() => {
    const str = [description, credit]
      .filter(str => str && str !== '')
      .join(' | ')
    if (str === '') {
      return null
    }
    return <DescriptionAndCredit children={str} />
  }, [credit, description])

  if (name) {
    name = name.trim()
  }

  const targetId = useMemo(() => {
    const randomNum = Math.floor(Math.random() * 1000)
    return ['kaltura-player', name, randomNum].filter(str => !!str).join('-')
  }, [name])

  useEffect(() => {
    if (autoPlay) {
      setTargetPlayingNow(targetId, false)
    }

    if (
      (utils.isLive(name, liveEntries) && !inChanel26) ||
      videoWithSubtitles
    ) {
      setShowSubTitleBtn(true)
    }
  }, [
    autoPlay,
    inChanel26,
    liveEntries,
    name,
    setTargetPlayingNow,
    targetId,
    videoWithSubtitles,
  ])

  useEffect(() => {
    setAutoPlay(!fromParser && isDesktop() && props.video.autoPlay !== false)
    // if ((autoPlay && targetPlayNowArr[0] !== targetId && targetPlayNowArr.length > 0) || fromParser) {
    //     setAutoPlay(false)
    // }
  }, [fromParser, props.video.autoPlay])

  const _this = useMemo(
    () => ({
      defaultImages: playerConfig.fakeWindow.siteConfig.default_images,
      videPlaceHolder: <VideoPlaceHolder />,
    }),
    []
  )

  const subTitlesToggleHandler = useCallback(() => {
    const options = {
      path: '/',
      expires: new Date(
        new Date().getTime() +
          1000 * 60 * 60 * subtitlesData.video_accessibility_cookie_hour
      ),
    }
    cookies.set(
      subtitlesData.video_accessibility_cookie_name,
      !subTitlesOn,
      options
    )
    setSubTitlesOn(prevState => {
      return !prevState
    })
    window.location.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTitlesOn, subtitlesData])

  // const { country } = useGeoPluginFetch()
  const blockAds = useDetectAdBlock()
  const blockedTaboola = useTaboola().scriptLoad === 'failed'
  const country = useGeoManifestFetch()
  if (country === false) {
    return <></>
  }
  // TODO: Check if behavior is correct after interface change
  const blockRT = !!ads.block?.dfp || !!ads.block?.player

  if (copyright) {
    return <Copyrights src={copyright} />
  }

  //set placehholder if failed to load video data
  if (
    (utils.isEmptyStr(kalturaId) && utils.isEmptyStr(name)) ||
    (utils.isLivePage(name) && utils.isEmptyStr(name))
  ) {
    prettyLog(
      'Player: 152',
      {
        msg: 'empty video',
        props: props,
      },
      'error'
    )
    return _this.videPlaceHolder
  }

  let kalturaIDParam, kalturaContentDuration
  if (kalturaId === null || kalturaId === undefined) {
    kalturaIDParam = -1
    kalturaContentDuration = -1
  } else {
    kalturaIDParam = kalturaId
    kalturaContentDuration = duration
  }

  // //Get Live Entry
  if (utils.isLive(name, liveEntries)) {
    const mobile = +(utils.isMobile() || playerConfig.inWebView)
    const isGlobal = +(country !== 'Israel')
    const withAccessibility = +subTitlesOn
    const entryRef = ('e' +
      mobile +
      isGlobal +
      withAccessibility) as LiveRefEntryKey
    const videoFetch = subTitlesOn ? name + '_captions' : name
    const entries =
      page?.SiteMeta?.config?.player?.liveEntries[videoFetch] ||
      playerConfig.fakeWindow.siteConfig.liveRefId[videoFetch]
    kalturaIDParam = entries ? entries.entries[entryRef] : ''
    inChanel26 = entries ? entries.mod === mode.BB : false
  }

  //block add get from  pagemeta
  if (blockAds || blockedTaboola) {
    gtag.fireEvents({
      name: 'AdBlock_Page_Load', //country_restricted
      category: 'Adds',
      googleOnly: false,
      label: 'ad blocker enabled',
    })

    let dynamicImageSrcBlockAds = imageSrcBlockAds

    if (_this.defaultImages) {
      dynamicImageSrcBlockAds = utils.isNews()
        ? _this.defaultImages.news.ad_block
        : _this.defaultImages.tv.ad_block
    }

    return (
      <ImgWithRatio
        loading='lazy'
        kalturaClass={'AdsBlockIMG'}
        src={imageSrcBlockAds}
      />
    )
  }

  //get kaltura image poster
  if (!poster && !fromParser && img) {
    if (img) {
      poster = img
    } else {
      const defaultImage = playerConfig.fakeWindow.siteConfig.default_images
      poster = utils.isNews()
        ? defaultImage.news.default_image
        : defaultImage.tv.default_image
    }
  }
  if (autoPlay === null) {
    return <></>
  }
  const getVideoTitle = () => {
    if (!fromParser) {
      return video.description || pageMeta.title
    }
    return video.description || ''
  }

  const Kaltura_Props: KalturaProps = {
    autoPlay: autoPlay,
    poster: poster,
    kalturaId: kalturaIDParam,
    videoRef: name,
    name: name,
    contentDuration: kalturaContentDuration,
    inChanel26: inChanel26,
    fromParser: fromParser,
    blockRT: blockRT,
    pageType: pageType,
    country: country,
    loadKantarConfig: loadKantarConfig,
    levels: levels,
    targetId: targetId,
    defaultImages: _this.defaultImages,
    item: item,
    playerOnly: playerOnly,
    subTitlesOn,
    sendPal: sendPal !== 'off',
    title: getVideoTitle(),
  }
  if (!!credit && !credit.includes('צילום')) {
    credit = `צילום: ${credit}`
  }
  const enable_kantar = page?.SiteMeta?.config?.player?.kantar?.enable || false
  const handelTouchEnd = () => {
    setUserActive(true)
    setTimeout(() => setUserActive(false), 3000)
  }

  return (
    <WrapStyledPlayerComp
      theme={{ fromParser: fromParser }}
      onMouseEnter={() => setUserActive(true)}
      onMouseLeave={() => setUserActive(false)}
      onTouchStart={handelTouchEnd}
      key={name}
    >
      <GeoTestButton country={country} kid={kalturaIDParam} />
      <StyledPlayerComp
        className={cx([
          'player-comp ',
          utils.isLive(name, liveEntries) && 'live-player',
          fromParser && 'fromParser',
        ])}
        data-duration={kalturaContentDuration}
        theme={{
          poster: poster,
          inEmbed: page?.isPlayerEmbed,
          show: playerStatePaused,
        }}
        data-auto-play={autoPlay ? 'on' : 'off'}
      >
        {playerScript.kalturaReady &&
        (!isShowingMaavaron || !isDesktop() || page?.isPlayerEmbed) &&
        (playerScript.kantarReady || !enable_kantar) ? (
          <Kaltura {...Kaltura_Props} />
        ) : (
          ''
        )}
      </StyledPlayerComp>
      {showSubTitleBtn ? (
        <SubTitlesBtn
          toggle={subTitlesToggleHandler}
          subTitlesOn={subTitlesOn}
          theme={{
            userActive: userActive,
          }}
        />
      ) : (
        ''
      )}
      {descriptionAdCreditEl}
    </WrapStyledPlayerComp>
  )
}

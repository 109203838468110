import { IKalturaConfig, IPlayerConfig } from '../Models/PlayerModels'
import { playerConfig } from './constants'
import { utils } from './utils'
import { translations } from '../Kaltura/translations'

export const kalturaConfig = ({
  targetId,
  autoPlay,
  enable_full_screen,
  shareConfig,
  livePageConfig,
  bitrate,
}: IKalturaConfig) => {
  const config: IPlayerConfig = {
    targetId: targetId,
    provider: {
      partnerId: playerConfig.fakeWindow.siteConfig.kaltura.partnerId,
      uiConfId: playerConfig.fakeWindow.siteConfig.kaltura.uiConfId,
    },
    playback: {
      autoplay: autoPlay ? 'inview' : autoPlay,
      allowMutedAutoPlay: utils.getParameterByName('mute') === 'true',
      inBrowserFullscreen: enable_full_screen,
      screenLockOrientionMode: playerConfig.inWebView && 'landscape',
    },
    plugins: {
      share: {
        ...shareConfig,
        uiComponent: {
          label: 'shareBottom',
          area: 'BottomBarRightControls',
          replaceComponent: 'Volume',
        },
      },
    },
    cast: {
      receiverApplicationId: 'CF3B07A1',
    },
    advertising: {},
    sources: {},
    ui: {
      components: {},
      locale: 'he',
      translations: {
        he: { ...translations.he },
      },
    },
    player: {
      ...livePageConfig,
    },
    abr: {
      enabled: true,
      fpsDroppedFramesInterval: 5000,
      fpsDroppedMonitoringThreshold: 0.2,
      capLevelOnFPSDrop: false,
      capLevelToPlayerSize: false,
      restrictions: {
        minBitrate: 0,
        maxBitrate: parseInt(bitrate + '000') || 720000,
        maxHeight: parseInt(bitrate) || 720,
      },
    },
    // network: {
    //   requestFilter: networkRequestFilter,
    // },
  }
  return config
}

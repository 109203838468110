import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { clientConfig } from '../client/clientConfig'
import {
  HeaderProvider,
  HeroProvider,
  OverlayProvider,
  PageProvider,
  ThemeProvider,
} from '../contexts'
import { IPage } from '../types'
import { prettyLog } from '../utils'
import PlayerContextProvider from './Player/Store/PlayerContext'

type Props = {
  page: IPage
  children: React.ReactNode
}

export const Providers = ({ page, children }: Props) => {
  const [thePage, setThePage] = useState<IPage>(page)
  const { asPath } = useRouter()

  useMemo(() => {
    const setNewPage = async () => {
      const fetchPreview = async () => {
        const response = await fetch(
          clientConfig.dataQuerySource + asPath + '&qdata=json'
        )

        if (!response.ok) {
          prettyLog('PageProvider', response, 'error')
          return false
        }

        return response.json()
      }
      if (asPath.includes('preview=true')) {
        const fetchResponse = await fetchPreview()

        if (fetchResponse) {
          setThePage({
            ...fetchResponse,
            isPreview: true,
            isDarkTheme: [
              'live',
              'gridvod',
              'hpvod',
              'tvguide',
              'genresvod',
            ].includes(page.PageMeta.pageType),
          })
          return
        }
      }
      setThePage({
        ...page,
        isPreview: false,
        isDarkTheme: [
          'live',
          'gridvod',
          'hpvod',
          'tvguide',
          'genresvod',
        ].includes(page.PageMeta.pageType),
        isPlayerEmbed: asPath.match(/^\/embed\//) !== null,
      })
    }

    setNewPage()
  }, [page, asPath])

  return (
    <OverlayProvider>
      <PlayerContextProvider>
        <PageProvider page={thePage}>
          <ThemeProvider page={thePage}>
            <HeaderProvider>
              <HeroProvider>{children}</HeroProvider>
            </HeaderProvider>
          </ThemeProvider>
        </PageProvider>
      </PlayerContextProvider>
    </OverlayProvider>
  )
}

/* eslint-disable */
// @ts-nocheck
import cx from 'classnames'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import Cookies from 'universal-cookie'
import { clientConfig } from '../../../client/clientConfig'
import {
  isAndroidApp,
  isDesktop,
  isIosApp,
  isMobile,
  isNativeApp,
} from '../../../client'
import { useGooglePal, usePage, usePageVisibility } from '../../../contexts'
import { usePlayerStore, useRootStore } from '../../../store'
import { isUserLoggedIn, prettyLog, sendGA4 } from '../../../utils'
import {
  DfpTimer,
  dfpUtils,
  PLAYER_EVENT,
  playerConfig,
  Timer,
  toggleGeoLocation,
  useEventListener,
  utils,
} from '../Helpers'
import ReactDOM from 'react-dom'
import { IShareConfig, KalturaProps, PlayerState } from '../Models/PlayerModels'
import { PlayerContext } from '../Store/PlayerContext'
import { ImgWithRatio } from '../Styles/Player.styles'
import { KlaturaPlayerDiv, KlaturaPlayerWrp } from './Kaltura.styles'
import { config } from '../../../lib'
import { AdS, INextVideos } from '../../../types'
import {
  Loader,
  NextVideoTimerScreen,
  PlayerMarqueTitle,
  RegistrationFormCTA,
} from '../components'
import { kalturaConfig } from '../Helpers/kaluraConfig'
import { GradientContainer } from '../components/PlayerMarqueTitle.styles'

export const Kaltura: React.FC<KalturaProps> = props => {
  const [liveSource, setLiveSource] = useState<null | string>(null)
  const googlePal = useGooglePal()
  const enable_full_screen = config.enable_full_screen != 'disabled'
  const progress_report_map = useMemo(() => [0.25, 0.5, 0.75, 0.9], [])
  const [shouldLog, setShouldLog] = useState(false)
  const [kalturaContainer, setKalturaContainer] = useState<HTMLElement | null>(
    null
  )

  useEffect(() => {
    setShouldLog(utils.getParameterByName('log_kaltura') === 'true')
  }, [])
  const [state, setState] = useReducer(
    (state: PlayerState, newState: Partial<PlayerState>) => ({
      ...newState,
    }),
    {
      showLoader: false,
      isErrorVideoLoad: false,
      isErrorOverseas: false,
      loadTaboola: false,
      isKalturaPicInPic: false,
      isPicInPic: false,
      isTaboolaInView: false,
      timer: null,
      isPlayingdInMobile: false,
      afterScrollPauseMobile: false,
      afterPageIsNotVisible: false,
      wasPaused: true,
    }
  )
  const pageData = usePage()
  const kantarSiteName = useMemo(() => {
    if (pageData) {
      const siteNames = pageData.SiteMeta.config?.player?.kantar?.site_name
      if (siteNames) {
        if (isDesktop()) {
          return siteNames.desktop
        }
        if (isIosApp()) {
          return siteNames.ios_app
        }
        if (isAndroidApp()) {
          return siteNames.android_app
        }
        return siteNames.mobile
      }
    }
    return null
  }, [pageData])
  const [kalturaPlayer, setKalturaPlayer] = useState<any>(null)
  const [beforePlaying, setBeforePlaying] = useState<boolean>(true)
  const kalturaRef = useRef<HTMLHeadingElement>(null)
  const adStorage = useRef<AdS>([])
  const videos = useRef<INextVideos>(pageData?.Content.Item?.video?.nextVideos)
  const isAdPlaying = useRef<boolean>(false)
  const [dfp_timer] = useState(new DfpTimer())
  const [nextVideoTimer] = useState(new DfpTimer())
  const WrpkalturaRef = useRef<HTMLHeadingElement>(null)
  const [videoEnded, setVideoEnded] = useState<boolean>()
  const [delayTimeEnded, setDelayTimeEnded] = useState<boolean>(false)
  const [replayClicked, setReplayClicked] = useState<boolean>(false)
  const [displayMarqueTitle, setDisplayMarqueTitle] = useState<'flex' | 'none'>(
    'none'
  )
  const [showNextVideoSLide, setShowNextVideoSlide] = useState<boolean>(false)
  const playNextVideoInterval = 15
  const replayVideo = useRef<string>(pageData?.Content.Item?.video?.kalturaId)
  const NextVideoTimerScreenMarqueTitle = useRef<string>(
    pageData?.Content.Item?.video?.description || pageData?.Content.Item?.title
  )
  const interval = useRef<NodeJS.Timeout>()
  const marqueTitleTimeout = useRef<NodeJS.Timeout>()
  const playNextVideoIsOn = pageData?.SiteMeta.config?.play_next_episode
  const autoPlayedItemsCounter = useRef<number>(1)
  const isPaused = useRef(false)
  const [playerScript] = useRootStore(state => [state.playerScript])
  const {
    setPlayerStatePaused,
    playerStatePaused,
    setPlayerTitle,
    playerTitle,
  } = usePlayerStore()
  const playerCtx = useContext(PlayerContext)
  const {
    targetPlayNowArr,
    setTargetPlayingNow,
    setVideoPlaying,
    setVideoEndedId,
  } = playerCtx
  const { pageViewedInDay } = utils.getPageViewedInDay()
  const {
    kalturaId,
    pageType,
    inChanel26,
    videoRef,
    contentDuration,
    blockRT,
    country,
    poster,
    loadKantarConfig,
    levels,
    autoPlay,
    targetId,
    defaultImages,
    item,
    playerOnly,
    title,
    fromParser,
  } = props

  let G_CLICK_URL = null // Initialize g click URL

  const DFP_ADS_ON = useMemo(
    () =>
      !blockRT &&
      playerConfig.DFPParams.DFP_ON &&
      !(utils.getParameterByName('noVideoDFP') === 'yes'),
    []
  )

  const defaultImg = utils.isNews()
    ? defaultImages.news.unavailable
    : defaultImages.tv.unavailable
  const overseasImg = utils.isNews()
    ? defaultImages.news.overseas
    : defaultImages.tv.overseas
  const DFP_Timer_BB = playerConfig.DFPParams.timer_BB * 60000 //milliseconds
  const isPageVisible = usePageVisibility()

  const playAdNow = useCallback(
    (adtype: string) => {
      if (kalturaPlayer) {
        const ad = dfpUtils.getAdVideo(
          pageType,
          'bb',
          adtype,
          levels,
          pageViewedInDay,
          item
        )
        kalturaPlayer.ads.playAdNow([{ url: [ad] }])
      }
    },
    [kalturaPlayer, pageType, levels, pageViewedInDay, item]
  )

  const resetAfterMidroll = useCallback(() => {
    if (kalturaPlayer) {
      kalturaPlayer.plugins.ima.reset()
      kalturaPlayer.plugins.ima.loadMedia()
    }
  }, [kalturaPlayer])

  const INTERNAL_ERROR = useCallback(
    event => {
      shouldLog &&
        prettyLog('Kaltura: 213 IMA', { msg: 'INTERNAL_ERROR', event }, 'error')
    },
    [kalturaPlayer]
  )

  useEventListener(
    PLAYER_EVENT.ima.INTERNAL_ERROR,
    INTERNAL_ERROR,
    kalturaPlayer
  )

  const VAST_LOAD_TIMEOUT = useCallback(
    event => {
      shouldLog &&
        prettyLog(
          'Kaltura: 221 IMA',
          { msg: 'VAST_LOAD_TIMEOUT', event },
          'error'
        )
    },
    [kalturaPlayer]
  )

  useEventListener(
    PLAYER_EVENT.ima.VAST_LOAD_TIMEOUT,
    VAST_LOAD_TIMEOUT,
    kalturaPlayer
  )

  const VAST_TOO_MANY_REDIRECTS = useCallback(
    event => {
      shouldLog &&
        prettyLog(
          'Kaltura: 239 IMA',
          { msg: 'VAST_TOO_MANY_REDIRECTS', event },
          'error'
        )
    },
    [kalturaPlayer]
  )

  useEventListener(
    PLAYER_EVENT.ima.VAST_TOO_MANY_REDIRECTS,
    VAST_TOO_MANY_REDIRECTS,
    kalturaPlayer
  )

  const VAST_MALFORMED_RESPONSE = useCallback(
    event => {
      shouldLog &&
        prettyLog(
          'Kaltura: 221 IMA',
          { msg: 'VAST_MALFORMED_RESPONSE', event },
          'error'
        )
    },
    [kalturaPlayer]
  )

  useEventListener(
    PLAYER_EVENT.ima.VAST_MALFORMED_RESPONSE,
    VAST_MALFORMED_RESPONSE,
    kalturaPlayer
  )

  const VAST_ASSET_NOT_FOUND = useCallback(
    event => {
      shouldLog &&
        prettyLog(
          'Kaltura: 235 IMA',
          { msg: 'VAST_ASSET_NOT_FOUND', event },
          'error'
        )
    },
    [kalturaPlayer]
  )

  useEventListener(
    PLAYER_EVENT.ima.VAST_ASSET_NOT_FOUND,
    VAST_ASSET_NOT_FOUND,
    kalturaPlayer
  )

  const VAST_EMPTY_RESPONSE = useCallback(
    event => {
      shouldLog &&
        prettyLog(
          'Kaltura: 227 IMA',
          { msg: 'VAST_EMPTY_RESPONSE', event },
          'error'
        )
    },
    [kalturaPlayer]
  )

  useEventListener(
    PLAYER_EVENT.ima.VAST_EMPTY_RESPONSE,
    VAST_EMPTY_RESPONSE,
    kalturaPlayer
  )

  const AD_ERROR = useCallback(
    event => {
      shouldLog &&
        prettyLog('Kaltura: 220 IMA', { msg: 'AD_ERROR', event }, 'error')
    },
    [kalturaPlayer]
  )

  useEventListener(PLAYER_EVENT.AD_ERROR, AD_ERROR, kalturaPlayer)

  const ERROR_HANDLER = useCallback(
    (ev: any) => {
      if (kalturaPlayer) {
        const err = ev.payload

        if (err.code === 1002 && err.data.name === 'manifestLoadError') {
          setState({ isErrorVideoLoad: true })
        }

        if (parseInt(err.code) === 7002) {
          prettyLog('Kaltura: 185', err, 'error')

          if (parseInt(err.data.code) === 2001) {
            // country_restricted
            utils.isLive(videoRef) && toggleGeoLocation()
            setState({
              isErrorVideoLoad: true,
              isErrorOverseas: true,
            })
          } else {
            setState({ isErrorVideoLoad: true })
          }
        }
      }
    },
    [
      kalturaPlayer,
      autoPlay,
      contentDuration,
      country,
      kalturaId,
      pageData,
      videoRef,
    ]
  )
  useEventListener(PLAYER_EVENT.ERROR, ERROR_HANDLER, kalturaPlayer)

  const FIRST_PLAY_HANDLER = useCallback(() => {
    setVideoEnded(false)
    setDelayTimeEnded(false)
    setBeforePlaying(false)
    if (kalturaPlayer) {
      if (DFP_ADS_ON) {
        dfp_timer.start()
      }
      //TO CHECK
      // clearTimeout(window.refreshTimeOut);
      // clearTimeout(window.analiticsInterval);
      if (utils.isLivePage(pageType) || utils.isLive(videoRef) || inChanel26) {
        setTimeout(() => {
          try {
            kalturaPlayer.seekToLiveEdge()
          } catch (e) {
            prettyLog('Kaltura: 254', e, 'error')
          }
        }, 50)
        kalturaPlayer.ui.getManager('timeline').setSeekbarPreview({
          hideTime: true,
          label: 'Dvr Time Component',
          presets: ['Live'],
          area: 'SeekBar',
          replaceComponent: 'SeekBarPreview',
          get: utils.dvrTimeComponent(KalturaPlayer),
          className: 'dvr-time',
          width: 35,
          height: 22,
        })
      }

      sendGA4({
        eventName: utils.isLive(videoRef)
          ? 'Video_content_start_live'
          : 'Video_content_start_1010',
        pageData: pageData,
        video_duration: contentDuration,
        ReferenceId: videoRef,
        is_autoplay: autoPlay ? 'yes' : 'no',
      })

      //TODO
      // if (isLivePage(pageType)) {
      //     this.surveyTimer.resume();
      // }
    }
  }, [
    kalturaPlayer,
    pageType,
    videoRef,
    inChanel26,
    pageData,
    contentDuration,
    autoPlay,
  ])
  useEventListener(PLAYER_EVENT.FIRST_PLAY, FIRST_PLAY_HANDLER, kalturaPlayer)

  const PLAY_HANDLER = useCallback(() => {
    dfp_timer.resume()
    setPlayerStatePaused(false)
    handleMouseMove()
    if (kalturaPlayer) {
      if (state.isKalturaPicInPic && targetPlayNowArr[0] !== targetId) {
        setTargetPlayingNow(targetId, true)
      }
      //TODO TIMER TO PAGE26
      if (inChanel26 && DFP_ADS_ON && state.timer) {
        state.timer.resume()
      }
    }
  }, [
    kalturaPlayer,
    state.isKalturaPicInPic,
    state.timer,
    setTargetPlayingNow,
    targetPlayNowArr,
    targetId,
    DFP_ADS_ON,
    inChanel26,
    videoRef,
  ])
  useEventListener(PLAYER_EVENT.PLAY, PLAY_HANDLER, kalturaPlayer)

  const PAUSE_HANDLER = useCallback(() => {
    setPlayerStatePaused(true)
    if (!state.isPicInPic) {
      setDisplayMarqueTitle('flex')
    }
    dfp_timer.pause()
    if (kalturaPlayer) {
      if (!kalturaPlayer.ads.isAdBreak()) {
        setVideoPlaying(false)
      }
      //TODO
      if (inChanel26 && DFP_ADS_ON && state.timer) {
        state.timer.pause()
      }
    }
  }, [
    kalturaPlayer,
    setVideoPlaying,
    state.timer,
    DFP_ADS_ON,
    inChanel26,
    videoRef,
    videos.current,
  ])
  useEventListener(PLAYER_EVENT.PAUSE, PAUSE_HANDLER, kalturaPlayer)

  const PLAYBACK_START_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      setState({ showLoader: true })
      //TODO
      // if (isLivePage(pageType)) {
      //     this.surveyTimer = new Timer(() => {
      //         this.props.showSurvey();
      //     }, 30000, "survey")
      // }

      sendGA4({
        eventName: utils.isLive(videoRef)
          ? 'Video_video_start_live'
          : playerOnly
          ? 'Embeded_video_start_1010'
          : 'Video_video_start_1010',
        pageData: pageData,
        video_duration: contentDuration,
        ReferenceId: videoRef,
        is_autoplay: autoPlay ? 'yes' : 'no',
      })

      if (DFP_ADS_ON) {
        if (!utils.isLive(videoRef)) {
          if (
            adStorage.current &&
            adStorage.current.length > 0 &&
            adStorage.current[0].preroll
          ) {
            playAd(adStorage.current[0].preroll.ad)
          } else {
            prettyLog(
              'Kaltura: 493',
              'There is no preroll, response empty',
              'error'
            )
          }
        }
        //show preroll
        if (inChanel26) {
          playAdNow('preroll')
        }
      }
      handleMouseMove()
    }
  }, [
    kalturaPlayer,
    videoRef,
    playerOnly,
    pageData,
    contentDuration,
    autoPlay,
    inChanel26,
    DFP_ADS_ON,
    playAdNow,
  ])
  useEventListener(
    PLAYER_EVENT.PLAYBACK_START,
    PLAYBACK_START_HANDLER,
    kalturaPlayer
  )

  const PLAYBACK_ENDED_HANDLER = useCallback(() => {
    dfp_timer.stop()
    setAdsCollection()
    interval.current && clearTimeout(interval.current)
    setVideoEnded(true)
    setVideoEndedId(kalturaId)
    if (kalturaPlayer) {
      if (playNextVideoIsOn) {
        nextVideoTimer.start()
        if (Object.entries(videos.current).length > 0) {
          interval.current = setTimeout(
            playNextVideo,
            playNextVideoInterval * 1000
          )
        }
      }
      sendGA4({
        eventName: 'Video_video_reach_100',
        pageData: pageData,
        video_duration: contentDuration,
        ReferenceId: videoRef,
        is_autoplay: autoPlay ? 'yes' : 'no',
      })
    }
  }, [
    kalturaPlayer,
    pageData,
    contentDuration,
    videoRef,
    autoPlay,
    setVideoEndedId,
    kalturaId,
  ])
  useEventListener(
    PLAYER_EVENT.PLAYBACK_ENDED,
    PLAYBACK_ENDED_HANDLER,
    kalturaPlayer
  )

  const LOADED_METADATA_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      setState({ showLoader: false })
    }
  }, [kalturaPlayer])
  useEventListener(
    PLAYER_EVENT.Core.LOADED_METADATA,
    LOADED_METADATA_HANDLER,
    kalturaPlayer
  )

  const WAITING_HANDLER = useCallback(
    (ev: any) => {
      if (kalturaPlayer) {
        console.log(
          '%cKaltura ad event\n%cWAITING',
          'background-color:black;color:white;padding:2px 5px;',
          'background-color:lime;padding: 2px 5px;color: black;',
          ev
        )
        setState({ showLoader: true })
      }
    },
    [kalturaPlayer]
  )
  useEventListener(PLAYER_EVENT.Core.WAITING, WAITING_HANDLER, kalturaPlayer)

  const AD_MANIFEST_LOADED_HANDLER = useCallback(
    (ev: any) => {
      if (kalturaPlayer) {
        shouldLog &&
          prettyLog('Kaltura: 599', {
            ev: 'AD_MANIFEST_LOADED_HANDLER',
            payload: ev.payload,
          })
      }
    },
    [kalturaPlayer]
  )
  useEventListener(
    PLAYER_EVENT.Core.AD_MANIFEST_LOADED,
    AD_MANIFEST_LOADED_HANDLER,
    kalturaPlayer
  )

  const AD_LOADED_HANDLER = useCallback(
    (ev: any) => {
      shouldLog &&
        prettyLog('Kaltura: 612', {
          ev: 'AD_LOADED_HANDLER',
          payload: ev.payload,
        })
    },
    [kalturaPlayer]
  )
  useEventListener(
    PLAYER_EVENT.Core.AD_LOADED,
    AD_LOADED_HANDLER,
    kalturaPlayer
  )

  const PROGRESS_HANDLER = useCallback(() => {
    if (!utils.isLive(videoRef)) {
      if (
        DFP_ADS_ON &&
        Object.entries(adStorage.current).length &&
        adStorage.current[0].midroll?.timeOffset
      ) {
        if (
          dfp_timer.getState().time >= adStorage.current[0].midroll.timeOffset
        ) {
          playAd(adStorage.current[0].midroll.ad)
        }
      }
    }
    if (
      !utils.isLive(videoRef) &&
      progress_report_map[0] < kalturaPlayer?.currentTime / contentDuration
    ) {
      sendGA4({
        eventName: `Video_video_reach_${progress_report_map[0] * 100}`,
        pageData: pageData,
        video_duration: contentDuration,
        ReferenceId: videoRef,
        is_autoplay: autoPlay ? 'yes' : 'no',
      })

      progress_report_map.shift()
    }
  }, [
    autoPlay,
    contentDuration,
    kalturaPlayer?.currentTime,
    pageData,
    progress_report_map,
    videoRef,
  ])
  useEventListener(PLAYER_EVENT.Core.PROGRESS, PROGRESS_HANDLER, kalturaPlayer)

  const AD_STARTED_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      isAdPlaying.current = true
      setState({ showLoader: false })

      //TODO
      if (inChanel26 && DFP_ADS_ON && state.timer) {
        state.timer.pause()
      }
    }
  }, [kalturaPlayer, state.timer, inChanel26, DFP_ADS_ON])
  useEventListener(
    PLAYER_EVENT.Core.AD_STARTED,
    AD_STARTED_HANDLER,
    kalturaPlayer
  )

  const AD_RESUMED_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      console.log('AD_RESUMED')
      setVideoPlaying(true)
      isAdPlaying.current = true
      if (targetPlayNowArr[0] !== targetId) {
        setTargetPlayingNow(targetId, true)
      }
    }
  }, [
    kalturaPlayer,
    setTargetPlayingNow,
    targetPlayNowArr,
    targetId,
    setVideoPlaying,
    videoRef,
  ])

  useEventListener(
    PLAYER_EVENT.Core.AD_RESUMED,
    AD_RESUMED_HANDLER,
    kalturaPlayer
  )

  const AD_PAUSED_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      setVideoPlaying(false)
      isAdPlaying.current = false
    }
  }, [kalturaPlayer, setVideoPlaying, videoRef])

  useEventListener(
    PLAYER_EVENT.Core.AD_PAUSED,
    AD_PAUSED_HANDLER,
    kalturaPlayer
  )

  const AD_BREAK_START_HANDLER = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    () => {
      adStorage.current.shift()
      dfp_timer.pause()
      if (kalturaPlayer) {
        //TODO
        // console.log('%cKaltura ad event\n%cAD_BREAK_START', 'background-color:black;color:white;padding:2px 5px;', 'background-color:lime;padding: 2px 5px;', ev.payload);
        // this.props.adStateChanged("on");
        // const {adBreak} = ev.payload;
        // switch (adBreak._type) {
        //     case "preroll":
        //         this.adType = "Pre-Roll"
        //         if (isLivePage(pageType)) {
        //             this.surveyTimer.pause();
        //         }
        //         break
        //     case "midroll":
        //         this.adType = "Mid-Roll"
        //         break
        //     case "postroll":
        //         this.adType = "Post-Roll"
        //         break
        // }

        console.log('AD_BREAK_START')
        isAdPlaying.current = true
      }
    },
    [kalturaPlayer]
  )
  useEventListener(
    PLAYER_EVENT.Core.AD_BREAK_START,
    AD_BREAK_START_HANDLER,
    kalturaPlayer
  )

  const AD_BREAK_END_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      console.log('AD_BREAK_END')
      kalturaPlayer.muted ? (kalturaPlayer.muted = false) : ''
      isAdPlaying.current = false
    }
  }, [kalturaPlayer])
  useEventListener(
    PLAYER_EVENT.Core.AD_BREAK_END,
    AD_BREAK_END_HANDLER,
    kalturaPlayer
  )

  const ADS_COMPLETED_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      console.log('ADS_COMPLETED')
      //show midroll
      if (inChanel26 && DFP_ADS_ON) {
        const timer = new Timer(() => {
          resetAfterMidroll()
          playAdNow('midroll')
        }, DFP_Timer_BB)
        setState({ timer: timer })
      }
    }
  }, [
    kalturaPlayer,
    inChanel26,
    DFP_ADS_ON,
    DFP_Timer_BB,
    resetAfterMidroll,
    playAdNow,
  ])
  useEventListener(
    PLAYER_EVENT.Core.ADS_COMPLETED,
    ADS_COMPLETED_HANDLER,
    kalturaPlayer
  )

  /**
   * If the G_CLICK_URL is defined, open it in a new window
   */
  const handleGClick = () => {
    if (G_CLICK_URL) {
      window.open(G_CLICK_URL)
      setTimeout(() => {
        if (kalturaPlayer.paused) {
          kalturaPlayer.play()
        }
      }, 800)
    }
  }

  const FRAG_FIRST_PLAY_HANDLER = useCallback(() => {
    if (kalturaPlayer?._localPlayer?._engine?._mediaSourceAdapter?._hls) {
      /*
       * Attach click event listener to player overlay area
       */
      if (kalturaRef.current) {
        const wrappedElement = document.getElementById(
          kalturaRef.current.id
        ) as HTMLElement
        const interactiveDiv = wrappedElement.querySelector(
          '.playkit-overlay-action'
        ) as HTMLElement

        if (interactiveDiv) {
          interactiveDiv.addEventListener('click', handleGClick)
          interactiveDiv.addEventListener('touchstart', handleGClick, {
            passive: true,
          })
        }
      }

      kalturaPlayer._localPlayer._engine._mediaSourceAdapter._hls.on(
        'hlsFragChanged',
        (data, obj) => {
          const ASSET_TAG = 'EXT-X-ASSET'
          if (obj.frag.tagList.length > 0) {
            for (const [i, tagArr] of obj.frag.tagList.entries()) {
              if (tagArr.length && tagArr[0] == ASSET_TAG) {
                try {
                  /**
                   * It takes a string like `"foo=bar, baz=qux"` and returns an object like `{foo:
                   * "bar", baz: "qux"}`
                   * @returns An object with the key being the first match and the value being the
                   * second match.
                   */
                  const parseAttrListCustom = input => {
                    let match
                    const attrs = {}
                    const quote = '"'
                    const ATTR_LIST_REGEX =
                      /\s*(.+?)\s*=((?:\".*?\")|.*?)(?:,|$)/g
                    ATTR_LIST_REGEX.lastIndex = 0
                    while ((match = ATTR_LIST_REGEX.exec(input)) !== null) {
                      let value = match[2]

                      if (
                        value.indexOf(quote) === 0 &&
                        value.lastIndexOf(quote) === value.length - 1
                      ) {
                        value = value.slice(1, -1)
                      }

                      attrs[match[1]] = value
                    }
                    return attrs
                  }
                  const attr = parseAttrListCustom(tagArr[1])
                  if (
                    attr &&
                    Object.keys(attr).length &&
                    attr.hasOwnProperty('L')
                  ) {
                    if (G_CLICK_URL != attr.L) {
                      G_CLICK_URL = attr.L
                    }
                    break
                    // break when ASSET with URL found
                  }
                } catch (error) {
                  G_CLICK_URL = null
                }
              }

              // if last tag and ASSET tag not found set G_CLICK_URL to null
              if (i + 1 == obj.frag.tagList.length) {
                G_CLICK_URL = null
              }
            }
          }
        }
      )
    }
  }, [kalturaPlayer])

  useEventListener(
    PLAYER_EVENT.Core.FIRST_PLAY,
    FRAG_FIRST_PLAY_HANDLER,
    kalturaPlayer
  )

  const AD_SKIPPED_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      console.log('AD_SKIPPED')
      //show midroll
      if (inChanel26 && DFP_ADS_ON) {
        const timer = new Timer(() => {
          resetAfterMidroll()
          playAdNow('midroll')
        }, DFP_Timer_BB)
        setState({ timer: timer })
      }
    }
  }, [
    DFP_ADS_ON,
    DFP_Timer_BB,
    inChanel26,
    kalturaPlayer,
    playAdNow,
    resetAfterMidroll,
  ])
  useEventListener(
    PLAYER_EVENT.Core.AD_SKIPPED,
    AD_SKIPPED_HANDLER,
    kalturaPlayer
  )

  const PLAYING_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      setState({ showLoader: false })
      setVideoPlaying(true)
    }
  }, [kalturaPlayer, setVideoPlaying])
  useEventListener(PLAYER_EVENT.Core.PLAYING, PLAYING_HANDLER, kalturaPlayer)

  const USER_CLICKED_PLAY_HANDLER = useCallback(() => {
    if (kalturaPlayer && targetPlayNowArr[0] !== targetId) {
      setTargetPlayingNow(targetId, true)
    }
  }, [kalturaPlayer, setTargetPlayingNow, targetPlayNowArr, targetId])
  useEventListener(
    PLAYER_EVENT.UI.USER_CLICKED_PLAY,
    USER_CLICKED_PLAY_HANDLER,
    kalturaPlayer
  )

  const [setIsPlayerFullscreen, isPlayerFullscreen] = useRootStore(state => [
    state.setIsPlayerFullscreen,
    state.isPlayerFullscreen,
  ])
  const ENTER_FULLSCREEN_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      if (playerConfig.inWebView) {
        utils._postMessage('playerEnteringFullScreen')
      }
      setIsPlayerFullscreen(true)
    }
  }, [kalturaPlayer])
  useEventListener(
    PLAYER_EVENT.Core.ENTER_FULLSCREEN,
    ENTER_FULLSCREEN_HANDLER,
    kalturaPlayer
  )

  const EXIT_FULLSCREEN_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      if (playerConfig.inWebView) {
        utils._postMessage('playerExitingFullScreen')
      }
      setIsPlayerFullscreen(false)
    }
  }, [kalturaPlayer])
  useEventListener(
    PLAYER_EVENT.Core.EXIT_FULLSCREEN,
    EXIT_FULLSCREEN_HANDLER,
    kalturaPlayer
  )

  const ENTER_PICTURE_IN_PICTURE_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      setState({ isKalturaPicInPic: true })
    }
  }, [kalturaPlayer])
  useEventListener(
    PLAYER_EVENT.Core.ENTER_PICTURE_IN_PICTURE,
    ENTER_PICTURE_IN_PICTURE_HANDLER,
    kalturaPlayer
  )

  const LEAVE_PICTURE_IN_PICTURE_HANDLER = useCallback(() => {
    if (kalturaPlayer) {
      setState({ isKalturaPicInPic: false })
    }
  }, [kalturaPlayer])
  useEventListener(
    PLAYER_EVENT.Core.LEAVE_PICTURE_IN_PICTURE,
    LEAVE_PICTURE_IN_PICTURE_HANDLER,
    kalturaPlayer
  )

  const animateOutPicInPic = (target: HTMLElement) => {
    if (target.classList.contains('pictureinpicturemode')) {
      target.classList.add('pictureinpicturemodeout')
      setTimeout(() => {
        target.classList.remove('pictureinpicturemode')
        target.classList.remove('pictureinpicturemodeout')
      }, 300)
    }
  }

  const creatCloseBtn = (target: HTMLElement) => {
    if (!target.querySelector('.picInPicClose')) {
      const node = document.createElement('button') as HTMLElement
      node.classList.add('picInPicClose')
      node.addEventListener('click', () => {
        kalturaPlayer.pause()
        animateOutPicInPic(target)
        setState({ isPicInPic: false, isTaboolaInView: false })
      })
      target.appendChild(node)
    }
  }

  const animateInPicInPic = useCallback(
    (target: HTMLElement) => {
      target.classList.add('pictureinpicturemode')
      target.classList.remove('pictureinpicturemodeout')
      creatCloseBtn(target)
    },
    [creatCloseBtn]
  )

  const trackScrolling = useCallback(() => {
    let wrappedElement, video
    if (kalturaRef.current) {
      wrappedElement = document.getElementById(
        kalturaRef.current.id
      ) as HTMLElement
      video = document.querySelector(
        '#' + kalturaRef.current.id + ' video'
      ) as HTMLVideoElement
    }
    const el = document.querySelector(
      '#taboola-below-vod-thumbnails,#taboola-below-portal-thumbnails'
    ) as HTMLElement
    let videoPause = video ? video.paused : true
    if (isAdPlaying.current) {
      videoPause = false
    }

    if (kalturaPlayer && utils.isMobile() && wrappedElement) {
      if (utils.outOfView(wrappedElement)) {
        if (!videoPause && document.pictureInPictureElement === null) {
          kalturaPlayer.pause()
          setState({
            isPlayingdInMobile: true,
            afterScrollPauseMobile: true,
          })
        } else if (state.isPlayingdInMobile) {
          setState({ isPlayingdInMobile: false })
        }
      } else if (!state.isPlayingdInMobile && state.afterScrollPauseMobile) {
        kalturaPlayer.play()
        setState({ afterScrollPauseMobile: false })
      }
    } else if (
      wrappedElement &&
      !state.isErrorVideoLoad &&
      !state.isKalturaPicInPic &&
      (!videoPause || state.isTaboolaInView || state.isPicInPic)
    ) {
      if (kalturaRef.current !== null) {
        const target = document.querySelector(
          '#' + kalturaRef.current.id + ' .kaltura-player-container '
        ) as HTMLElement
        const maxwidth = target.parentElement?.offsetWidth
        target.style.maxWidth = maxwidth + 'px'

        if (utils.outOfView(wrappedElement)) {
          if (!state.isTaboolaInView) {
            if (el && utils.isTaboolaElementInView(el)) {
              kalturaPlayer.pause()
              animateOutPicInPic(target)
              setState({
                isPicInPic: false,
                isTaboolaInView: true,
              })
            } else {
              animateInPicInPic(target)
              setState({
                isPicInPic: true,
              })
            }
          }
        } else {
          if (state.isTaboolaInView) {
            kalturaPlayer.play()
            setState({ isTaboolaInView: false })
          }
          animateOutPicInPic(target)
          setState({ isPicInPic: false })
        }
      }
    }
  }, [
    state.isPicInPic,
    state.isTaboolaInView,
    animateInPicInPic,
    isAdPlaying.current,
    state.isErrorVideoLoad,
    state.isKalturaPicInPic,
    kalturaPlayer,
    state.isPlayingdInMobile,
    state.afterScrollPauseMobile,
  ])

  useEventListener('scroll', trackScrolling, window)

  useEffect(() => {
    isPaused.current = playerStatePaused
  }, [playerStatePaused])

  const handleMouseMove = useCallback(ev => {
    marqueTitleTimeout.current && clearTimeout(marqueTitleTimeout.current)

    if (!state.isPicInPic) {
      setDisplayMarqueTitle('flex')
      hidePlayerTitle()
    }
  }, [])

  const handleMouseLeave = useCallback(() => {
    marqueTitleTimeout.current && clearTimeout(marqueTitleTimeout.current)
    if (!isPaused.current) {
      setDisplayMarqueTitle('none')
    }
  }, [])

  const hidePlayerTitle = useCallback(() => {
    marqueTitleTimeout.current = setTimeout(() => {
      if (!isPaused.current) {
        setDisplayMarqueTitle('none')
      }
    }, 3000)
  }, [])

  useEffect(() => {
    if (kalturaRef.current && !beforePlaying) {
      kalturaRef.current.addEventListener('mousemove', handleMouseMove)
      kalturaRef.current.addEventListener('mouseleave', handleMouseLeave)
      kalturaRef.current.addEventListener('touchstart', handleMouseMove, {
        passive: true,
      })
    }

    return () => {
      if (kalturaRef.current && beforePlaying) {
        kalturaRef.current.removeEventListener('touchstart', handleMouseMove)
        kalturaRef.current.removeEventListener('mousemove', handleMouseMove)
        kalturaRef.current.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [kalturaRef.current, playerStatePaused, beforePlaying])

  useEffect(() => {
    if (
      utils.isLiveRef(videoRef) &&
      !inChanel26 &&
      googlePal
      // && googlePal !== 'undefined'
    ) {
      let source
      if (isMobile()) {
        source =
          clientConfig.liveSources?.mobile[
            props.subTitlesOn ? 'live_with_subs' : 'live_no_subs'
          ]
      } else {
        source =
          clientConfig.liveSources?.desktop[
            props.subTitlesOn ? 'live_with_subs' : 'live_no_subs'
          ]
      }
      const params = [
        `player.pal=${
          ['undefined', 'off'].includes(googlePal) ? '' : googlePal
        }`,
      ]
      const dmpid = localStorage.getItem('dmp-publisher-dmpid')
      if (dmpid) {
        params.push(`player.idxu=${dmpid}`)
      }
      const dxsegvalue = localStorage.getItem(
        'dmp-publisher-prevDefinitionsIds'
      )
      if (dxsegvalue) {
        params.push(`player.idxseg=${dxsegvalue}`)
      }
      setLiveSource(`${source}?${params.join('&')}`)
    }
  }, [googlePal])

  useEffect(() => {
    if (WrpkalturaRef.current && kalturaPlayer) {
      const container = WrpkalturaRef.current.querySelector(
        '.kaltura-player-container'
      )
      if (container) {
        setKalturaContainer(container as HTMLElement)
      }
    }
  }, [kalturaPlayer, WrpkalturaRef.current])

  //kaltura config
  useEffect(() => {
    if (utils.isLiveRef(videoRef) && !inChanel26 && !liveSource) {
      return
    }
    if (window.KalturaPlayer && kalturaRef.current) {
      let embedUrl = `${location.protocol}//${location.host}/embed${window.location.pathname}`
      embedUrl = utils.insertParam('ref', videoRef, embedUrl)
      embedUrl = utils.insertParam('k_id', kalturaId, embedUrl)
      embedUrl = utils.insertParam('duration', contentDuration, embedUrl)
      embedUrl = utils.insertParam('poster', poster, embedUrl)

      let livePageConfig = {}
      const shareConfig: IShareConfig = {
        disable: false,
        embedUrl: embedUrl,
        shareUrl: window.location.href,
      }

      //live page config
      if (
        pageType === 'pageLive' ||
        pageType === 'live' ||
        inChanel26 ||
        utils.isLive(videoRef)
      ) {
        livePageConfig = {
          dvr: true,
          type: 'Live',
          playback: {
            autoplay: autoPlay ? 'inview' : autoPlay,
            inBrowserFullscreen: enable_full_screen,
            screenLockOrientionMode: playerConfig.inWebView && 'landscape',
          },
        }
        //to disable share
        shareConfig.disable = true
      }
      if (pageData?.isPlayerEmbed) {
        shareConfig.disable = true
      }
      try {
        const config = kalturaConfig({
          targetId,
          autoPlay: autoPlay ? 'inview' : autoPlay,
          enable_full_screen,
          shareConfig,
          livePageConfig,
          bitrate: pageData.SiteMeta.config?.player?.bitrate || '720',
        })

        if (DFP_ADS_ON) {
          config.plugins = {
            ...config.plugins,
            ima: {
              locale: 'he',
              adsRenderingSettings: {
                uiElements: ['adAttribution', 'countdown'],
              },
            },
          }
          if (
            utils.isLivePage(pageType) ||
            (utils.isLive(videoRef) && !inChanel26)
          ) {
            if (clientConfig.live_ima === 'enabled') {
              config.plugins = {
                ...config.plugins,
                ima: {
                  ...config.plugins.ima,
                  adTagUrl: dfpUtils.getAdVideo(
                    pageType,
                    'live',
                    'preroll',
                    levels,
                    pageViewedInDay,
                    item,
                    googlePal
                  ),
                },
              }
              if (clientConfig.live_mid_rolls === 'enabled') {
                const cookies = new Cookies()
                const accessibilityOn = cookies.get(
                  playerConfig.video_accessibility_cookie_name
                )
                const assetKey = dfpUtils.geAssetKey(country, videoRef)
                const custParams = dfpUtils.getCustParams(
                  pageType,
                  levels,
                  'midrollLive',
                  pageViewedInDay,
                  item
                )
                config.plugins.imadai =
                  accessibilityOn === 'true'
                    ? { disable: true }
                    : {
                        assetKey: assetKey,
                        debug: true,
                        adTagParameters: {
                          cust_params: custParams,
                        },
                      }
              }
            } else {
              delete config.plugins.ima //mid rolls
              delete config.plugins.imadai //pre rolls
            }
          }
        }

        if (poster) {
          config.sources.poster = poster
        }

        let kalturaPlayer = window.KalturaPlayer.getPlayer(targetId)
        if (kalturaPlayer === null) {
          try {
            if (utils.isLive(videoRef) && !inChanel26) {
              config.sources = {
                ...config.sources,
                hls: [
                  {
                    id: '1_ufpwi64o,applehttp',
                    mimetype: 'application/x-mpegURL',
                    url: liveSource,
                  },
                ],
                dvr: true,
                dash: [
                  {
                    id: '1_ufpwi64o,mpegdash',
                    mimetype: 'application/dash+xml',
                    url: liveSource,
                  },
                ],
              }
            }
            shouldLog && prettyLog('Kaltura config: 1202', config)
            kalturaPlayer = window.KalturaPlayer.setup(config)

            if (
              kantarSiteName &&
              utils.isLive(videoRef) &&
              !inChanel26 /* && ["prod", "staging"].includes(utils.getEnv())*/
            ) {
              const kantarConfig = {
                siteName: kantarSiteName,
                streamName: 'channel13',
              }
              try {
                window.KalturaPlayer.plugins.kantar.track(
                  kalturaPlayer,
                  kantarConfig
                )
              } catch (e) {
                console.log(
                  '%cKaltura',
                  'background-color:darkred;color:white;padding:2px 5px;',
                  e
                )
              }
            }

            if (utils.isLive(videoRef) && !inChanel26) {
              kalturaPlayer.play()
            } else {
              kalturaPlayer.loadMedia({ entryId: kalturaId })
            }
            kalturaPlayer.ready().then(() => {
              setState({ loadTaboola: true })

              //add AdFlag
              if (kalturaRef.current !== null) {
                const adFlaf = '<div class="adFlag">יצאנו לפרסומות</div>'
                kalturaRef.current.firstElementChild?.insertAdjacentHTML(
                  'afterbegin',
                  adFlaf
                )
              }

              //hide quality menu on mouse out
              utils.hideQualityMenu(targetId)

              //full screen hide bottom bar
              if (kalturaRef !== null && kalturaRef.current !== null) {
                kalturaRef.current.addEventListener('mousemove', () => {
                  if (kalturaRef.current !== null) {
                    utils.hideBottomMenu(kalturaRef.current)
                  }
                })
              }
            })
            setKalturaPlayer(kalturaPlayer)
          } catch (e) {
            prettyLog('Kaltura: 1265', [e, kalturaPlayer], 'error')
          }
        }
      } catch (e: any) {
        prettyLog('Kaltura: 1435', { e }, 'error')

        setState({ isErrorVideoLoad: true })
      }
    }
  }, [
    googlePal,
    DFP_ADS_ON,
    autoPlay,
    contentDuration,
    country,
    inChanel26,
    item,
    kalturaId,
    levels,
    loadKantarConfig,
    pageType,
    // pageViewedInDay,
    poster,
    playerScript.kantarReady,
    targetId,
    videoRef,
    window.KalturaPlayer,
    liveSource,
  ])

  useEffect(() => {
    if (
      kalturaPlayer &&
      targetPlayNowArr[0] !== targetId &&
      targetPlayNowArr.length > 0
    ) {
      kalturaPlayer.pause()
    }
  }, [kalturaPlayer, targetPlayNowArr, targetId])

  const playAd = (ad: string) => {
    kalturaPlayer.ads.playAdNow([{ url: [ad] }])
  }

  useEffect(() => {
    setAdsCollection()
  }, [])

  const setAdsCollection = () => {
    if (!(inChanel26 || utils.isLive(videoRef))) {
      const ad =
        dfpUtils.getAdVideo(
          pageType,
          'vod',
          '',
          levels,
          pageViewedInDay,
          item
        ) +
        '&vid=' +
        dfpUtils.AdVidDur(contentDuration)
      const ads = dfpUtils.parseAdsIntoCollection(ad)
      ads.then((data: AdS) => {
        adStorage.current = data
      })
    }
  }

  useEffect(() => {
    if (kalturaPlayer && utils.isMobile()) {
      if (kalturaRef.current) {
        const wrappedElement = document.getElementById(
          kalturaRef.current.id
        ) as HTMLElement
        if (isPageVisible) {
          if (state.afterPageIsNotVisible && !utils.outOfView(wrappedElement)) {
            !state.wasPaused && kalturaPlayer.play()
            setState({ afterPageIsNotVisible: false })
          }
        } else if (!state.afterPageIsNotVisible) {
          const wasPaused = kalturaPlayer.paused
          kalturaPlayer.pause()
          setState({
            afterPageIsNotVisible: true,
            wasPaused: wasPaused,
          })
        }
      }
    }
  }, [kalturaPlayer, isPageVisible, autoPlay, state.afterPageIsNotVisible])

  const getNextVideoTitle = () => {
    if (videos.current[1]) {
      return videos.current[1].videoTitle
    }
    return ''
  }

  const getCurrentVideoTitle = () => {
    return videos.current[0].videoTitle
  }

  const playNextVideo = () => {
    const { videoTitle, kalturaId } = videos.current[0]
    interval.current && clearTimeout(interval.current)
    setDelayTimeEnded(true)
    setReplayClicked(false)
    nextVideoTimer.stop()
    replayVideo.current = kalturaId
    NextVideoTimerScreenMarqueTitle.current = videoTitle
    sendGA4({
      eventName: isNativeApp() ? 'appnext_video_loader' : 'next_video_loader',
      pageData: pageData,
      url: pageData.Content.Item?.link,
      current_video_title: getCurrentVideoTitle(),
      next_video_title: getNextVideoTitle(),
      num: autoPlayedItemsCounter.current++,
    })
    setPlayerTitle(videoTitle || '')
    kalturaPlayer.loadMedia({ entryId: kalturaId }).then(() => {
      kalturaPlayer.play()
    })
    const videosList = [...videos.current]
    videosList.shift()
    videos.current = [...videosList]
  }

  const replayPreviousVideo = (entryId: string) => {
    setDelayTimeEnded(true)
    setReplayClicked(true)
    interval.current && clearTimeout(interval.current)
    nextVideoTimer.stop()
    kalturaPlayer.loadMedia({ entryId: entryId }).then(() => {
      kalturaPlayer.play()
    })
  }

  useEffect(() => {
    setShowNextVideoSlide(
      videoEnded &&
        !delayTimeEnded &&
        videos.current &&
        Object.entries(videos.current).length > 0 &&
        playNextVideoIsOn &&
        kalturaContainer &&
        !state.isPicInPic
    )
  }, [videoEnded, delayTimeEnded])

  if (
    clientConfig.bigBrotherRegistration === 'on' &&
    inChanel26 &&
    !isUserLoggedIn()
  ) {
    return <RegistrationFormCTA title={'האח הגדול לייב'} />
  }

  const getPlayerTitle = () => {
    if (fromParser) {
      return title
    }
    if (playerTitle) {
      return playerTitle
    }
    if (pageData.Content.Item?.video.description) {
      return pageData.Content.Item?.video.description
    }
    return pageData.Content.Item?.title
  }

  return (
    <KlaturaPlayerWrp
      ref={WrpkalturaRef}
      className={cx(['wrp-kaltura', 'player-con'])}
      theme={{ showingAd: isAdPlaying.current }}
      data-kid={kalturaId}
      data-name={videoRef}
    >
      {!utils.isLive(videoRef) &&
        !isAdPlaying.current &&
        kalturaContainer &&
        ReactDOM.createPortal(
          <GradientContainer
            display={displayMarqueTitle}
            className={isPlayerFullscreen ? 'full-screen' : ''}
          >
            <PlayerMarqueTitle
              className={'playerMarquee ' + 'full-screen-' + isPlayerFullscreen}
              fontSize={utils.isMobile() ? 14 : isPlayerFullscreen ? 36 : 21}
              width={isPlayerFullscreen ? '100%' : '800px'}
              color={'#fff'}
              text={
                replayClicked
                  ? NextVideoTimerScreenMarqueTitle.current
                  : getPlayerTitle()
              }
              speed={30}
              display={displayMarqueTitle} //'flex'
            />
          </GradientContainer>,
          kalturaContainer
        )}
      {showNextVideoSLide &&
        ReactDOM.createPortal(
          <NextVideoTimerScreen
            isPlayerFullscreen={isPlayerFullscreen}
            playerTitle={
              replayClicked
                ? NextVideoTimerScreenMarqueTitle.current
                : getPlayerTitle()
            }
            replayVideo={replayVideo.current}
            nextVideo={videos.current[0]}
            playNextVideoInterval={playNextVideoInterval}
            replayPreviousVideo={replayPreviousVideo}
            playNextVideo={playNextVideo}
          />,
          kalturaContainer
        )}

      {state.isErrorVideoLoad ? (
        <ImgWithRatio
          loading='lazy'
          kalturaClass={'kalturaImgErr'}
          src={state.isErrorOverseas ? overseasImg : defaultImg}
          alt='error loading video'
        />
      ) : (
        <KlaturaPlayerDiv
          ref={kalturaRef}
          id={targetId}
          theme={{ show: playerStatePaused }}
        />
      )}
      <Loader showLoader={state.showLoader} />
    </KlaturaPlayerWrp>
  )
}
